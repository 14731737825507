export interface aISelectionModel {
    aiSelectionType: AiSelectionType;
    description: string;
}

export enum AiSelectionType {
    CREATE = 'CREATE',
    TRANSLATE = 'TRANSLATE',
    SUMMARIZE = 'SUMMARIZE',
    FIX = 'FIX',
    EXTRACTLINKS = 'EXTRACTLINKS'
}
