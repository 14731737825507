import { Injectable } from "@angular/core";
import { ApiOperation, ApiProcessorService, ApiRequest } from "../api-processor.service";
import { AITemplateDto } from "../../model/ai/aITemplateDto";
import { AITranslateDto } from "../../model/ai/aITranslateDto";
import { AISummarizeDto } from "../../model/ai/aISummarizeDto";
import { AIFixDto } from "../../model/ai/aIFixDto";
import { AIExtractLinksDto } from "../../model/ai/aIExtractLinksDto";
import { Observable } from "rxjs";

@Injectable()
export class AIService {

    constructor(private readonly apiProcessorService: ApiProcessorService) {  }

    public generate(aITemplate: AITemplateDto): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.POST;
        apiRequest.body = aITemplate;
        apiRequest.endpointUrl = '/api/templates/create-template-body';
        apiRequest.responseType = 'string';

        return this.apiProcessorService.proccessRequest(apiRequest);
    }

    public translate(aiTranslate: AITranslateDto): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.POST;
        apiRequest.body = aiTranslate;
        apiRequest.endpointUrl = '/api/text/translate-text';
        apiRequest.responseType = 'string';

        return this.apiProcessorService.proccessRequest(apiRequest);
    }

    public summarize(aiSummarize: AISummarizeDto): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.POST;
        apiRequest.body = aiSummarize;
        apiRequest.endpointUrl = '/api/text/summarize-text';
        apiRequest.responseType = 'string';

        return this.apiProcessorService.proccessRequest(apiRequest);
    }

    public fix(aIFix: AIFixDto): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.POST;
        apiRequest.body = aIFix;
        apiRequest.endpointUrl = '/api/text/fix-text';
        apiRequest.responseType = 'string';

        return this.apiProcessorService.proccessRequest(apiRequest);
    }

    public extractLinks(aiExtractLinks: AIExtractLinksDto): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.POST;
        apiRequest.body = aiExtractLinks;
        apiRequest.endpointUrl = '/api/text/extract-links-text';
        apiRequest.responseType = 'string';

        return this.apiProcessorService.proccessRequest(apiRequest);
    }
}
