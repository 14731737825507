import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPermissionsModule } from 'ngx-permissions';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatRippleModule, MatNativeDateModule, DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { MatFormFieldModule, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { OverlayModule } from '@angular/cdk/overlay';
import { IntegerDirective } from './directives/integer.directive';
import { ReloadDirective } from './directives/reload.directive';
import { ResizableDirective } from './directives/resizable.directive';
import { DragIntoFoldersDirective } from './directives/drag-into-folders.directive';
import { DragAndDropDirective } from './directives/drag-and-drop.directive';
import { DateDayOnlyFormatPipe } from './pipes/date-day-only-format.pipe';
import { DateFormatPipe } from './pipes/date-format.pipe';
import { DateDayHourMinSecPipe } from './pipes/date-day-hour-min-sec.pipe';
import { SanitizeHtml } from './pipes/sanitize-html.pipe';
import { SanitizeUrl } from './pipes/sanitize-url.pipe';
import { SortAlphabetical } from './pipes/sort-alphabetical.pipe';
import { FileNodeFromTemplateFolder } from './pipes/file-node-from-template-folder.pipe';
import { FileNodeFromTableOfContent } from './pipes/file-node-from-table-of-content.pipe';
import { CtboxFooterLandingComponent } from './components/ctbox-footer-landing/ctbox-footer-landing.component';
import { CtboxLogoComponent } from './components/ctbox-logo/ctbox-logo.component';
import { CtboxHeaderComponent } from './components/ctbox-header/ctbox-header.component';
import { CtboxHeaderSectionsComponent } from './components/ctbox-header-sections/ctbox-header-sections.component';
import { CtboxVideoHomeComponent } from './components/ctbox-video-home/ctbox-video-home.component';
import { LoginStatusComponent } from './components/login-status/login-status.component';
import { CtboxFormEmailComponent } from './components/ctbox-form-email/ctbox-form-email.component';
import { CtboxNotificationComponent } from './components/ctbox-notification/ctbox-notification.component';
import { CtboxSelectionListComponent } from './components/ctbox-selection-list/ctbox-selection-list.component';
import { CtboxSearchListComponent } from './components/ctbox-search-list/ctbox-search-list.component';
import { CtboxGenericDialogComponent } from './components/ctbox-generic-dialog/ctbox-generic-dialog.component';
import { CtboxGenericDialogDataComponent } from './components/ctbox-generic-dialog-data/ctbox-generic-dialog-data.component';
import { CtboxEditNameComponent } from './components/ctbox-edit-name/ctbox-edit-name.component';
import { DocumentClauseRevisionPreviewComponent } from './components/document-clause-revision-preview/document-clause-revision-preview.component';
import { DocumentClauseRevisionSendToFirmComponent } from './components/document-clause-revision-send-to-firm/document-clause-revision-send-to-firm.component';
import { TemplateRevisionPreviewComponent } from './components/template-revision-preview/template-revision-preview.component';
import { TemplateLldRevisionPreviewComponent } from './components/template-lld-revision-preview/template-lld-revision-preview.component';
import { TemplatesDocumentRevisionListComponent } from './components/templates-document-revision-list/templates-document-revision-list.component';
import { DragAndDropComponent } from './components/drag-and-drop/drag-and-drop.component';
import { HtmlViewerComponent } from './components/html-viewer/html-viewer.component';
import { ScrollToTopComponent } from './components/scroll-to-top/scroll-to-top.component';
import { SearchBoxComponent } from './components/search-box/search-box.component';
import { HtmlComparerComponent } from './components/html-comparer/html-comparer.component';
import { CtboxButtonComponent } from './components/ctbox-button/ctbox-button.component';
import { ThreeStatesToggleComponent } from './components/three-states-toggle/three-states-toggle.component';
import { SignatureWithRoleComponent } from './components/signature-with-role/signature-with-role.component';
import { CBPdfViewerComponent } from './components/cb-pdf-viewer/cb-pdf-viewer.component';
import { CtboxValidateNameComponent } from './components/ctbox-validate-name/ctbox-validate-name.component';
import { CtboxTabsComponent } from './components/ctbox-tabs/ctbox-tabs.component';
import { CtboxNodeDraggableDirective } from './directives/draggable/ctbox-node-draggable.directive';
import { CtboxMenuComponent } from './components/ctbox-menu/ctbox-menu.component';
import { CdkTreeModule } from '@angular/cdk/tree';
import { CtboxTreeComponent } from './components/ctbox-tree/ctbox-tree.component';
import { CtboxTreeNodeComponent } from './components/ctbox-tree/ctbox-tree-node/ctbox-tree-node.component';
import { CtboxTreeActionsComponent } from './components/ctbox-tree/ctbox-tree-actions/ctbox-tree-actions.component';
import { CtboxTreeActionImageComponent } from './components/ctbox-tree/ctbox-tree-action-image/ctbox-tree-action-image.component';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { CtboxInfoComponent } from './components/ctbox-info/ctbox-info.component';
import { CtboxHelpComponent } from './components/ctbox-help/ctbox-help.component';
import { CtboxHelpBodyComponent } from './components/ctbox-help-body/ctbox-help-body.component';
import { CtboxWorkflowUserComponent } from './components/ctbox-workflow/ctbox-workflow-user/ctbox-workflow-user.component';
import { CtboxWorkflowPanelComponent } from './components/ctbox-workflow/ctbox-workflow-panel/ctbox-workflow-panel.component';
import { CtboxWorkflowStateComponent } from './components/ctbox-workflow/ctbox-workflow-state/ctbox-workflow-state.component';
import { CtboxWorkflowInfoComponent } from './components/ctbox-workflow/ctbox-workflow-info/ctbox-workflow-info.component';
import { CtboxAiSelectionComponent } from './components/ai/ctbox-ai-selection/ctbox-ai-selection.component';
import { DocumentStateComponent } from './components/document-state/document-state.component';
import { DocumentStateService } from './components/document-state/service/document-state.service';
import { IDocumentStateService } from './components/document-state/service/document-state.service.interface';
import { CtboxDoubleIconComponent } from './components/ctbox-double-icon/ctbox-double-icon.component';
import { ContextualHelpComponent } from './components/contextual-help/contextual-help/contextual-help.component';
import { TreeContextualHelpComponent } from './components/contextual-help/tree-contextual-help/tree-contextual-help.component';
import { AdministrationMainContextualHelpComponent } from './components/contextual-help/administration-main-contextual-help/administration-main-contextual-help.component';
import { MyClausesContextualHelpComponent } from './components/contextual-help/my-clauses-contextual-help/my-clauses-contextual-help.component';
import { ClausesEditionContextualHelpComponent } from './components/contextual-help/clauses-edition-contextual-help/clauses-edition-contextual-help.component';
import { MyTemplatesContextualHelpComponent } from './components/contextual-help/my-templates-contextual-help/my-templates-contextual-help.component';
import { TemplatesEditionContextualHelpComponent } from './components/contextual-help/templates-edition-contextual-help/templates-edition-contextual-help.component';
import { MyDocumentsContextualHelpComponent } from './components/contextual-help/my-documents-contextual-help/my-documents-contextual-help.component';
import { DocumentsMainEditionContextualHelpComponent } from './components/contextual-help/documents-main-edition-contextual-help/documents-main-edition-contextual-help.component';
import { DocumentsDraftContextualHelpComponent } from './components/contextual-help/documents-draft-contextual-help/documents-draft-contextual-help.component';
import { DocumentsReviewContextualHelpComponent } from './components/contextual-help/documents-review-contextual-help/documents-review-contextual-help.component';
import { DocumentsCompleteContextualHelpComponent } from './components/contextual-help/documents-complete-contextual-help/documents-complete-contextual-help.component';
import { DocumentsSendToSignContextualHelpComponent } from './components/contextual-help/documents-send-to-sign-contextual-help/documents-send-to-sign-contextual-help.component';
import { DocumentsSignedContextualHelpComponent } from './components/contextual-help/documents-signed-contextual-help/documents-signed-contextual-help.component';
import { ListClauseModalComponent } from './components/wysiwyg-editor/list-clause-modal/list-clause-modal.component';
import { NewClauseModalComponent } from './components/wysiwyg-editor/new-clause-modal/new-clause-modal.component';
import { CkeditorFormsCheckBoxService } from './components/wysiwyg-editor/services/ckeditor-forms-checkbox.service';
import { CkeditorFormsRadioService } from './components/wysiwyg-editor/services/ckeditor-forms-radio.service';
import { CkeditorFormsSelectService } from './components/wysiwyg-editor/services/ckeditor-forms-select.service';
import { CkeditorFormsTextAreaService } from './components/wysiwyg-editor/services/ckeditor-forms-text-area.service';
import { CkeditorFormsTextFieldService } from './components/wysiwyg-editor/services/ckeditor-forms-text-field.service';
import { CkeditorFormsUtilsService } from './components/wysiwyg-editor/services/ckeditor-forms-utils.service';
import { ICkeditorFormsUtilsService } from './components/wysiwyg-editor/services/ckeditor-forms-utils.service.interface';
import { ShowHideElementsService } from './components/wysiwyg-editor/services/show-hide-elements.service';
import { IShowHideElementsService } from './components/wysiwyg-editor/services/show-hide-elements.service.interface';
import { CtboxActionsButtonsComponent } from './components/ctbox-actions-buttons/ctbox-actions-buttons.component';
import { SearchBoxFilesComponent } from './components/search-box-files/search-box-files.component';
import { CtboxSidenavComponent } from './components/ctbox-sidenav/ctbox-sidenav.component';
import { CtboxVersionsComponent } from './components/ctbox-versions/ctbox-versions.component';
import { CtboxVersionsListComponent } from './components/ctbox-versions/version-list/version-list.component';
import { CtboxVersionComparatorComponent } from './components/ctbox-versions/version-comparator/version-comparator.component';
import { CtboxVersionAddCommentComponent } from './components/ctbox-versions/version-add-comment/version-add-comment.component';
import { CtboxLoadLocalFilesComponent } from './components/ctbox-load-local-files/ctbox-load-local-files.component';
import { SortTypeAlphabetical } from './pipes/sort-type-alphabetical.pipe';
import { NotFoundComponent } from './components/ctbox-not-found/ctbox-not-found.component';
import { FileUploadManagementComponent } from './components/file-upload-management/file-upload-management.component';
import { UploadedFileItemComponent } from './components/uploaded-file-item/uploaded-file-item.component';
import { UploadFileComponent } from './components/upload-file/upload-file.component';
import { StepperComponent } from './components/stepper/stepper.component';
import { UploadFileStepperComponent } from './components/stepper/upload-file-stepper/upload-file-stepper.component';
import { SelectFolderStepperComponent } from './components/stepper/select-folder-stepper/select-folder-stepper.component';
import { UploadedFilesResultStepperComponent } from './components/stepper/uploaded-files-result-stepper/uploaded-files-result-stepper.component';
import { FileSizeExtensionPipe } from './pipes/file-size-extension.pipe';
import { CkeditorReadOnlyClauseWidgetService } from './components/wysiwyg-editor/services/ckeditor-read-only-clause-widget.service';
import { CtboxExportComponent } from './components/ctbox-export/ctbox-export.component';
import { TemplateFileStateComponent } from './components/file-state/template/template-file-state/template-file-state.component';
import { ClauseFileStateComponent } from './components/file-state/clause/clause-file-state/clause-file-state.component';
import { UpdateFileStateComponent } from './components/file-state/update-file-state/update-file-state.component';
import { CtboxExportResultComponent } from './components/ctbox-export-result/ctbox-export-result.component';
import { CkeditorPluginTableToolsService } from './components/wysiwyg-editor/services/ckeditor-plugin-table-tools.service';
import { DecodeHtml } from './pipes/decode-html.pipe';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { WysiwygEditorComponent } from './components/wysiwyg-editor/wysiwyg-editor.component';
import { SignaturePlugin } from './components/wysiwyg-editor/custom-plugins/signature/signature-plugin';
import { EditorUtilsService } from './components/wysiwyg-editor/services/editor-utils.service';
import { SignatureUtilsService } from './components/wysiwyg-editor/custom-plugins/signature/signature-utils.service';
import SignatureFormView from './components/wysiwyg-editor/custom-plugins/signature/signature-config/signature-form-view.directive';
import { SignatureConfigurationComponent } from './components/signature-configuration/signature-configuration.component';

export const MY_FORM_FIELD_APPEARANCE = {
    appearance: 'outline'
};

export const MY_DATE_FORMAT = {
    parse: {
        dateInput: 'DD/MM/YYYY',
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

const SHARED_DECLARATIONS = [
    // Directives
    DragIntoFoldersDirective,
    DragAndDropDirective,
    IntegerDirective,
    ReloadDirective,
    ResizableDirective,
    // Pipes
    DateDayOnlyFormatPipe,
    DateFormatPipe,
    DateDayHourMinSecPipe,
    SanitizeHtml,
    SanitizeUrl,
    DecodeHtml,
    SortAlphabetical,
    SortTypeAlphabetical,
    FileNodeFromTemplateFolder,
    FileNodeFromTableOfContent,
    // Components
    CtboxFooterLandingComponent,
    CtboxLogoComponent,
    CtboxHeaderComponent,
    CtboxHeaderSectionsComponent,
    CtboxVideoHomeComponent,
    LoginStatusComponent,
    TemplateFileStateComponent,
    UpdateFileStateComponent,
    ClauseFileStateComponent,
    // UserNoLicenseComponent,
    CtboxFormEmailComponent,
    CtboxNotificationComponent,
    CtboxSelectionListComponent,
    CtboxSearchListComponent,
    CtboxGenericDialogComponent,
    CtboxGenericDialogDataComponent,
    CtboxEditNameComponent,
    DocumentClauseRevisionPreviewComponent,
    DocumentClauseRevisionSendToFirmComponent,
    TemplateRevisionPreviewComponent,
    TemplateLldRevisionPreviewComponent,
    TemplatesDocumentRevisionListComponent,
    DragAndDropComponent,
    HtmlViewerComponent,
    ScrollToTopComponent,
    SearchBoxComponent,
    SearchBoxFilesComponent,
    HtmlComparerComponent,
    CtboxButtonComponent,
    ThreeStatesToggleComponent,
    SignatureWithRoleComponent,
    CBPdfViewerComponent,
    SignatureConfigurationComponent,
    CtboxValidateNameComponent,
    CtboxTabsComponent,
    CtboxTreeComponent,
    CtboxNodeDraggableDirective,
    CtboxMenuComponent,
    CtboxTreeNodeComponent,
    LoadingSpinnerComponent,
    CtboxTreeActionsComponent,
    CtboxTreeActionImageComponent,
    CtboxInfoComponent,
    CtboxHelpComponent,
    CtboxHelpBodyComponent,
    CtboxWorkflowUserComponent,
    CtboxWorkflowPanelComponent,
    CtboxWorkflowStateComponent,
    CtboxWorkflowInfoComponent,
    CtboxAiSelectionComponent,
    DocumentStateComponent,
    CtboxDoubleIconComponent,
    ContextualHelpComponent,
    TreeContextualHelpComponent,
    AdministrationMainContextualHelpComponent,
    MyClausesContextualHelpComponent,
    ClausesEditionContextualHelpComponent,
    MyTemplatesContextualHelpComponent,
    TemplatesEditionContextualHelpComponent,
    MyDocumentsContextualHelpComponent,
    DocumentsMainEditionContextualHelpComponent,
    DocumentsDraftContextualHelpComponent,
    DocumentsCompleteContextualHelpComponent,
    DocumentsReviewContextualHelpComponent,
    DocumentsSendToSignContextualHelpComponent,
    DocumentsSignedContextualHelpComponent,
    NewClauseModalComponent,
    ListClauseModalComponent,
    TemplateLldRevisionPreviewComponent,
    CtboxActionsButtonsComponent,
    CtboxSidenavComponent,
    CtboxVersionsComponent,
    CtboxVersionsListComponent,
    CtboxVersionComparatorComponent,
    CtboxVersionAddCommentComponent,
    CtboxLoadLocalFilesComponent,
    NotFoundComponent,
    FileUploadManagementComponent,
    UploadedFileItemComponent,
    UploadFileComponent,
    StepperComponent,
    SelectFolderStepperComponent,
    UploadFileStepperComponent,
    UploadedFilesResultStepperComponent,
    NotFoundComponent,
    CtboxExportComponent,
    CtboxExportResultComponent,
    FileSizeExtensionPipe,
    WysiwygEditorComponent,
    SignaturePlugin,
    SignatureFormView
];

const SHARED_MODULES = [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPermissionsModule,
    NgxSpinnerModule,
    PdfViewerModule,
    DragDropModule,
    // Bootstrap
    NgbModule,
    CKEditorModule,
    // Material modules
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatMomentDateModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    CdkTreeModule,
    OverlayModule,
];

const SHARED_PROVIDERS = [
    // Material
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: MY_FORM_FIELD_APPEARANCE },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMAT },
    { provide: IDocumentStateService, useClass: DocumentStateService },
    CkeditorFormsCheckBoxService,
    CkeditorFormsRadioService,
    CkeditorFormsSelectService,
    CkeditorFormsTextAreaService,
    CkeditorFormsTextFieldService,
    CkeditorPluginTableToolsService,
    CkeditorReadOnlyClauseWidgetService,
    SignatureUtilsService,
    { provide: IShowHideElementsService, useClass: ShowHideElementsService },
    { provide: ICkeditorFormsUtilsService, useClass: CkeditorFormsUtilsService },
    DatePipe,
    EditorUtilsService
];

@NgModule({
    declarations: [
        ...SHARED_DECLARATIONS,
    ],
    imports: [
        ...SHARED_MODULES
    ],
    exports: [
        ...SHARED_DECLARATIONS,
        ...SHARED_MODULES
    ],
    providers: [
        ...SHARED_PROVIDERS
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA

    ]
})
export class SharedModule { }
