import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { invalidCharactersValidator } from 'src/app/core/shared/validators/invalidCharactersValidator';
import { aISelectionModel, AiSelectionType } from './aISelectionModel';
import { empty } from 'rxjs';

@Component({
    selector: 'app-ctbox-ai-selection.component',
    templateUrl: './ctbox-ai-selection.component.html',
    styleUrls: ['./ctbox-ai-selection.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CtboxAiSelectionComponent implements OnInit {

    @Input() public parentGroup: UntypedFormGroup;
    @Input() public controlName: string;

    public aISelection: aISelectionModel | null;

    public form: UntypedFormGroup;

    constructor() {    }

    public ngOnInit(): void {
        this.initializeFormGroup();
    }

    private initializeFormGroup(): void {
        this.form = new UntypedFormGroup(
            {
                description: new UntypedFormControl('', [Validators.maxLength(500), invalidCharactersValidator]),
            }
        );
    }

    public getDescription(): void {
        this.aISelection.description = this.form.controls.description.value;
    }

    public setAiSelectionType(type: string): void {
        if (!this.aISelection) {
            this.aISelection = {} as aISelectionModel;
        }

        switch ( type ) {
            case  'CREATE':
                this.aISelection.aiSelectionType = AiSelectionType.CREATE;
                break;
            case 'TRANSLATE':
                this.aISelection.aiSelectionType = AiSelectionType.TRANSLATE;
                break;
            case 'SUMMARIZE':
                this.aISelection.aiSelectionType = AiSelectionType.SUMMARIZE;
                break;
            case 'FIX':
                    this.aISelection.aiSelectionType = AiSelectionType.FIX;
                    break;
            case 'EXTRACTLINKS':
                    this.aISelection.aiSelectionType = AiSelectionType.EXTRACTLINKS;
                    break;
            default:
                this.aISelection = null;
                break;
         }
    }

    public GetAiType(): string {
        return String(this.aISelection.aiSelectionType);
    }
}
